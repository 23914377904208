<template>
  <div class="">
    <div class="row ">
      <div class="col-md-5 col-lg-6 col-xl-3 px-lg-6 my-5 align-self-center">
        <h1 class="text-center mb-2">
          Sign in
        </h1>
        <p class="text-muted text-center mb-5">
          Please sigin into your account below
        </p>

        <div class="form-group px-4">
          <ion-item class="">
            <ion-label position="floating">Username</ion-label>
            <ion-input
              type="text" 
              mode="ios" 
              accept="text"
              autocomplete="true"
              @change="handleUsername"
              v-model="form.username"
              v-on:keyup.enter="signin"></ion-input>
          </ion-item> 

          <ion-item>
            <ion-label position="floating">Password</ion-label>
            <ion-input
              type="password" 
              mode="ios" 
              accept="password"
              autocomplete="true"
              @change="handlePassword"
              v-model="form.password"
              v-on:keyup.enter="signin"></ion-input>
          </ion-item>

          <ion-item lines="none">
            <ion-label>
              <a href="/forgot-password"
              class="form-text small text-muted">
              Forgot password?
              </a>
            </ion-label>
            <!-- <ion-label slot="end">
              <small class="text-muted text-center">
                <a href="sign-up-cover.html">Sign up</a>
              </small>
            </ion-label> -->
          </ion-item>

          <button
            @click.prevent="signin"
            class="btn btn-lg w-100 btn-danger mb-3 mt-4">
            <ion-spinner v-if="isLoading" name="crescent" color="light">
            </ion-spinner>
            <span v-else>Sign in</span>
          </button>
          
          <div v-if="errorResponse" class="error-section mt-0 text-center">
            <p class="text-danger">{{ errorResponse.data }}</p>
          </div>

          <p class="text-center">
            <small class="text-muted text-center">
              Don't have an account yet?
              <a href="sign-up-cover.html">Sign up</a>.
            </small>
          </p>
        </div>
      </div>
      <div
        :style="currentBackground"
        class="col-md-7 col-lg-6 col-xl-9 d-none d-lg-block image-side" >
      </div>
    </div>
  </div>
</template>

<script>
import { IonSpinner, IonItem, IonInput, IonLabel } from "@ionic/vue"
import { createNamespacedHelpers } from 'vuex'
const AuthenticationModule = createNamespacedHelpers('authentication')

export default {
  name: 'LoginPageV2',
  props: ['backgroundImage'],
  components: {
    IonItem,
    IonInput,
    IonLabel,
    IonSpinner,
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      }
    }
  },
  setup(props) {    
    return {
      currentBackground: {
        backgroundImage: 'url(' + props.backgroundImage + ')',
        backgroundRepeat: ' no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100 %',
        height: '100vh'
      }
    }
  },
  computed: {
    ...AuthenticationModule.mapState(['isLoading', 'errorResponse']),
  },
  methods: {
    ...AuthenticationModule.mapMutations(['setUsername','setPassword']),
    ...AuthenticationModule.mapActions(['login']),

    handleUsername(event){
      this.setUsername(event.target.value);
    },
    handlePassword(event){
      this.setPassword(event.target.value);
    },
    async signin(){
      await this.login();
    }
  }
}
</script>

<style scoped lang="scss">
  .input-field {
    height: 50px;
    border-radius: 10px;
  }
</style>