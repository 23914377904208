// Written By : Nemese Kalubi
// Date: Sat Oct 22,2022

const BG = [
  // "lake.png",
  // "hike1.png",
  // "hike2.png",
  // "hike3.png",
  // "hike4.png",
  // "hike5.png",
  // "hike6.png",
  "hike7.png",
  "seattle.png",
  "IMG_1674.jpeg",
  "IMG_1673.jpeg",
  "IMG_1634.jpeg",
  "IMG_1627.jpeg",
  "IMG_1605.jpeg",
  "IMG_1578.jpeg",
  "IMG_1565.jpeg",
  "IMG_1492.jpeg",
  "IMG_1449.jpeg",
  "IMG_1428.jpeg",
  "IMG_1427.jpeg",
  "IMG_1387.jpeg",
  "IMG_1381.jpeg",
  "IMG_1325.jpeg",
  "IMG_1236.jpeg",
  "IMG_1198.jpeg",
  "IMG_1297.jpeg",
  "IMG_1242.jpeg",
  "IMG_1802.png",
  "IMG_1807.jpeg",
  "IMG_1750.jpeg",
  "IMG_1693.jpeg",
  "IMG_1829.jpeg",
  "IMG_1854.jpeg",
  "IMG_1855.jpeg",
  "IMG_1868.jpeg",
  "IMG_1766.jpeg",
  "wolves-bg.png",
  "african-bg.png",
  "greatSaltar.png",
  "greatSaltar2.png",
  "greatSaltar3.png",
  "bigCottonwood.png",
  "bigCottonwood2.png",
  "bigCottonwood3.png",
]

/**
 * background image
 * @returns {string} background image url
 */
export default function backgroundImage() {
  const randnumber = Math.floor(Math.random() * BG.length)
  return require(`../public/assets/img/backgrounds/${BG[randnumber]}`) 
}
