<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <LoginPageV2 :backgroundImage="backgroundImage"/>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonContent, IonPage } from "@ionic/vue";
import LoginPageV2 from "./LoginPageV2.vue";
import bg from '../../../../lib/bg'

export default {
  name: "Login",
  components: {
    IonPage,
    IonContent,
    LoginPageV2,
  },
  computed: {
    backgroundImage(){
      return bg()
    }
  }
};
</script>
